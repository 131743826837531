<template>
  <div class="p-4 bg-white rounded-lg">
    <div class="mb-7 mx-4 mt-6 d-flex align-center justify-space-between">
      <!--        <datatable-detail-->
      <!--          class="col-9 col-md-9 col-sm-9"-->
      <!--          :table_properties="tableProperties"-->
      <!--        ></datatable-detail>-->
      <div class="d-flex align-center">
        <button class="btn btn--export-filter">
          <span class="svg-icon">
            <v-icon size="18">mdi-export-variant</v-icon>
          </span>
          Builder
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
export default {
  name: "Products",
  // components: { BuilderDialog, DatatableDetail },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, false);
  },
};
</script>
