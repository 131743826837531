<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000px"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header py-4 align-center">
          <h4 class="mb-0 font-weight-bolder">Create Sku</h4>
          <v-spacer></v-spacer>
          <v-autocomplete
            v-model="formData.sku_id"
            :items="items"
            :loading="isLoading"
            :search-input.sync="search"
            :value="value"
            item-value="index"
            hide-details
            label="Please select the Sku name to create new sku data"
            @keyup.enter="getSkuItems"
            solo
            dense
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  Search for
                  <strong>Skus</strong>
                  (please type at least two characters and hit enter)
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="{ item }">
              <show-image :item="item.data.photo" class="mr-3"> </show-image>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.data.brand"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light-info ml-3"
            @click="dialog = false"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
          <!--begin::Body-->

          <form id="createForm" class="pa-3 pb-0">
            <v-text-field
              v-model.trim="$v.formData.price.$model"
              :error-messages="validateState('price') ? errorMessages : ''"
              :disabled="!formData.sku_id"
              type="number"
              label="Price"
              outlined
              dense
              @change="() => validateMinValue('price', 0)"
            ></v-text-field>
            <v-text-field
              v-model.trim="$v.formData.discounted_price.$model"
              :error-messages="
                validateState('discounted_price') ? errorMessages : ''
              "
              type="number"
              :disabled="!formData.sku_id"
              label="Discounted Price"
              outlined
              dense
              @change="() => validateMinValue('discounted_price', 0)"
            ></v-text-field>
            <v-file-input
              v-model="formData.image"
              :disabled="!formData.sku_id"
              show-size
              counter
              multiple
              dense
              outlined
              accept="image/png, image/jpeg, image/bmp"
              prepend-icon="mdi-camera"
              label="Image"
              clearable
            ></v-file-input>
            <add-parameter
              :parameters="parameters"
              @update="insertParameter"
              :disabled="!formData.sku_id"
              :ref="`AddParameter_${itemForAction.id}`"
            >
            </add-parameter>
            <v-switch
              v-model="formData.is_default"
              :disabled="!formData.sku_id"
              color="primary"
              label="Home Page"
            />
          </form>
          <!--end::Body-->

          <!--begin::Actions-->
          <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mr-3 px-5 py-3 ls1"
              @click="closeModal"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-info px-5 py-3 ls1"
              @click="submitEditForm"
            >
              Submit
            </button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
// import { getToken } from "@/core/services/jwt.service";
// import axios from "axios";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import ShowImage from "@/own/components/datatable/ShowImage.vue";
import AddParameter from "@/own/components/stores/products/AddParameter.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";
export default {
  name: "AddSku",
  mixins: [validationMixin, fieldValueValidation],
  validations: {
    formData: {
      sku_id: { required },
      product_id: { required },
      price: { required },
      discounted_price: { required },
    },
  },
  props: [
    "refresher",
    "loader",
    "brands",
    "stores",
    "parameters",
    "itemForAction",
  ],
  components: { ShowImage, AddParameter },
  data: () => ({
    dialog: false,
    formData: {
      sku_id: null,
      product_id: null,
      price: 0,
      discounted_price: 0,
      image: null,
      parameters: null,
      is_default: true,
    },
    errorMessages: ["This Field is required"],
    items: [],
    value: null,
    isLoading: false,
    model: null,
    maxCount: 50,
    search: null,
  }),
  mounted() {
    this.formData.product_id = this.itemForAction.id;
    this.resetData();
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
      this.resetData();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.formData[name];
      return $dirty ? $error : null;
    },
    closeModal() {
      this.dialog = !this.dialog;
      this.$refs[`AddParameter_${this.itemForAction.id}`].resetData();
      this.resetData();
    },
    insertParameter(val) {
      this.formData.parameters = val;
    },
    getSkuItems() {
      if (this.search.length < 2) {
        this.message(
          "Error",
          "You should input more than 2 Characters",
          "error"
        );
        return;
      }
      this.isLoading = true;
      const data = {
        q: this.search,
        max: this.maxCount,
      };
      ApiService.post("/stores/portal/skus/index", data)
        .then((data) => {
          if (data.data) {
            this.items = data.data.skus;
          }
          this.isLoading = false;
        })
        .catch(() => {
          this.loader(false);
        });
    },
    submitEditForm() {
      this.$v.formData.$touch();
      if (this.$v.formData.$anyError) {
        return;
      }
      let data = this.convertToFormData();
      if (!data) {
        this.message("Error", "You should input all data", "error");
        return;
      }
      this.loader(true);
      ApiService.post("/stores/portal/skus/store", data)
        .then(() => {
          this.message("Created", "The sku data has been created!", "success");
          this.closeModal();
          this.loader(false);
          this.refresher();
        })
        .catch(() => {
          this.loader(false);
        });
    },
    convertToFormData() {
      let data = new FormData();
      for (var key in this.formData) {
        if (key == "is_default") {
          if (this.formData[key] == false) data.append(key, 0);
          else data.append(key, 1);
        } else if (key == "image") {
          if (this.formData[key]) {
            this.formData[key].map((item) => {
              data.append("sliders[]", item);
            });
          }
        } else if (key == "parameters") {
          if (this.formData[key]) {
            this.formData[key].map((item) => {
              data.append("parameter_values[]", item);
            });
          }
        } else data.append(key, this.formData[key]);
      }
      return data;
    },
    message(title, message, icon) {
      Swal.fire({
        title: title,
        text: message,
        icon: icon,
        showConfirmButton: false,
        timer: 2500,
      });
    },
    resetData() {
      this.formData.sku_id = null;
      this.formData.parameters = null;
      this.formData.image = null;
      this.formData.price = 0;
      this.formData.discounted_price = 0;
      this.formData.is_default = true;
      this.items = [];
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
};
</script>
